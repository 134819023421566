/* Outer container for all elements */
.subSkillDetailContainer {
    margin: 0 10px 10px 0;
    border-radius: 5px;
    border: solid 1px #ddd;
    background-color: #fff;
    display: flex;
    flex-direction: column;
    padding: 10px;
}

.subSkillDetailContainerPdfMode {
    padding: 5px;
    margin: 0 5px 5px 0;
}

/* Special style for comunication to be at the bottom of the script */
.communicationDetail {
    margin-top: auto;
}
  
/* Container with the header (can also include the score) */
.skillHeader {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    margin: 0;
}

.skillName, .skillScore {
    font-weight: 700;
}

/* Container of each specific subSkill */
.subSkillColumn {
    display: flex;
    flex-direction: column;
    margin-top: 10px;
}

/* Container for name and question mark icon */
.subSkillNameContainer {
    display: flex;
    align-items: flex-end;
}

.subSkillName {
    display: flex;
    align-items: flex-end;
    color: #444;
    font-weight: 500;
}

.subSkillNamePdfMode {
    font-size: 12px;
}

.questionMarkIcon {
    display: inline-block;
    margin-left: 5px;
    position: relative;
    color: #444;
}

.infoIcon:hover + .tooltipText {
    visibility: visible;
    opacity: 1;
}

.tooltipText {
    visibility: hidden;
    background-color: #fff;
    color: #000;
    border: solid 1px #ddd;
    text-align: center;
    border-radius: 5px;
    padding: 5px;
    position: absolute;
    z-index: 5;
    bottom: 125%;
    transform: translateX(0);
    opacity: 0;
    transition: opacity 0.3s;
    min-width: 180px;
    min-height: 60px;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.3);
    font-family: 'Segoe UI', sans-serif;
}

/* Container of the subskill bar and the arrow */
.barAndArrowContainer {
    display: flex;
    flex-direction: row;
    justify-content: space-between; /* Adjusts space between bar and arrow */
    align-items: center; /* Vertically centers items in the container */
    width: 100%;
    gap: 10px;
}

/* Container of the score bar to enable colouring of background */
.subSkillBarContainer {
    display: flex;
    height: 5px;
    border-radius: 2.5px;
    background-color: #ddd;
    width: 100%;
    border-radius: 5px;
}

/* The score bar itself */
.subSkillBar {
    display: block;
    height: 100%;
    border-radius: 5px;
}

/* The arrow to expand or collapse the details */
.detailsArrow {
    cursor: pointer; /* Indicates this is clickable */
    color: #444;
}

/* Container that appears when arrow is clicked with the score description and rational */
.subSkillExpandedContent {
    margin: 0;
    border-bottom: solid 1px #ddd;
    padding-bottom: 5px;
}

.scoreDescription {
    font-weight: 500;
    padding-bottom: 5px;
}

.scoreDescriptionPdfMode {
    font-size: 12px;
}

.rationale {
    color: #444;
    font-family: 'Segoe UI', sans-serif;
}

.trailingSpace {
    display: inline-block; /* Ensures it doesn't cause a new line */
    width: 45px; 
    height: 10px;
}

.rationalePdfMode {
    font-size: 11px;
}