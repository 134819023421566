/* Common header styles */
.header {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  max-height: 40px;
  position: fixed;
  top: 0;
  z-index: 10;
  background-color: #fff;
  border-bottom: solid 1px #eee;
}

/*inner box to contain content width*/
.headerInnerContainer {
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 100%;
  justify-content: space-between;
  align-items: center;
  margin: 0 10px;
}

.lastArea {
  display: flex;
  flex-direction: row;
  gap: 10px;
  align-items: center;
}

/*logos*/
.degrauLogoHeader, .partnerLogo, .clickableDegrauLogoHeader {
  width: auto;
  max-height: 25px;
  max-width: 120px;
}

@media (max-width: 768px) {
  .partnerLogo {
    margin-right: 30px;
  }
}

.clickableDegrauLogoHeader {
  cursor: pointer;
}

/* name of job role in intro header*/
.roleName {
font-weight: bold;
font-size: 16px;
}

/* Navbar */
/* the buttons on the top ribbon */
.navbarButton {
  width: 30px;
  height: 30px;
  border-radius: 50%; /* Make it circular */
  background-color: #246E24;
  color: white;
  font-size: 14px;
  font-weight: 500;
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

/* the icons on the top ribbon buttons */
.navbarIcon {
  cursor: pointer;
  font-size: 16px;
  border-radius: 5px;
  padding: 5px;
}

.navbarButton:hover, .navbarButton:focus {
  outline: solid 1px #333;
  background-color: #1b521b;
}
