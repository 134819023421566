.generalInterviewInterface {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 100%;
  background-color: #eee;
  position: relative;
}

/* Full page container for the button */
.startInterviewContainer, .endInterviewContainer {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
}

.connectionError {
  background-color: #fff;
  color: #dc2626;
  padding: 0.75rem;
  text-align: center;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1000;
}

.resumedMessage {
  background-color: #fff;
  color: darkgreen;
  padding: 0.75rem;
  text-align: center;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1000;
}

/* Container for the chat/audio messages */
.chatContainer {
  width: 100%;
  box-sizing: border-box;
  height: calc(100% - 115px);
  overflow-y: auto;
}

/* Container for the voice buttons and visuals */
.controlGroup {
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  position: fixed;
  bottom: 0;
  width: 100%;
  max-width: 500px;
  gap: 10px;
  padding: 20px;
  box-sizing: border-box;
  background-color: #eee;
}

.micRecordingContainer {
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: center;
  align-items: center;
  gap: 15px;
}

.micRecordingTopLayer, .micRecordingBottomLayer {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: center;
  align-items: center;
  position: relative;
}

.volumeVisualizerContainer {
  width: 70%;
  height: 20px;
}

.recordingIcon {
  position: absolute;
  left: 15px;
  color: #ff0000;
  font-size: 18px;
}

.pulsingMic {
  animation: pulse 2s ease-in-out infinite;
}

@keyframes pulse {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.micRecordingBottomLayer {
  justify-content: space-between;
}

.voiceButton {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border: none;
  padding: 5px;
  cursor: pointer;
  color: #fff;
  font-size: 18px;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  position: relative;
  transition: all 0.2s ease;
}

.voiceButton::after {
  content: '';
  position: absolute;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  background-color: currentColor;
  opacity: 0.2;
  transform: scale(0);
  transition: transform 0.3s ease;
}

.voiceButton:active::after {
  transform: scale(2);
  opacity: 0;
}

.voiceButton:disabled {
  opacity: 0.5;
  cursor: not-allowed;
  background-color: #666;
}

.voiceButton:disabled:hover {
  transform: none;
}

.green {
  background-color: green;
}

.red {
  background-color: red;
}

.voiceButton:hover {
  outline: 1px solid #333;
}

/* Add these styles */
.statusMessage {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
  color: #666;
  font-size: 14px;
}

.sendingStatus {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
  font-size: 14px;
  color: green;
}

.micDisabledMessage {
  color: #333;
  font-size: 14px;
  text-align: center;
}